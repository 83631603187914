import iro from "@jaames/iro"
import { Controller } from "@hotwired/stimulus"
import { options } from "../../lib/picker"

export default class extends Controller {
  static targets = ["picker", "fields"]

  initialize() {
    this.element[this.identifier] = this
    this.element.controller = this
    this.updateInputWithMood = this.updateInputWithMood.bind(this)
    this.updateWheelWithMood = this.updateWheelWithMood.bind(this)
  }

  updateInputWithMood(event, fields) {
    const deg = fields.querySelector("input[name*=degree]")
    const dist = fields.querySelector("input[name*=distance]")
    const hsl = this.colorPicker.color.hsl

    deg.value = hsl.h
    dist.value = hsl.s
  }

  updateWheelWithMood(event) {
    this.activeFields = event.currentTarget
    const deg = event.currentTarget.querySelector("input[name*=degree]")
    const dist = event.currentTarget.querySelector("input[name*=distance]")

    const h = deg.value
    const s = dist.value
    const l = this.colorPicker.color.hsl.l

    this.colorPicker.color.hsl = { h, s, l }
  }

  removeActiveFields() {
    this.activeFields = null
  }

  connect() {
    const $ = window.$
    $(this.element).on("cocoon:before-insert", (event, jq) =>
      this.updateInputWithMood(event, jq[0])
    )
    const addBtn = this.pickerTarget
      .closest(".mood-picker")
      .querySelector(".mood-picker__add")
    let self = this

    // cocoon seems to rebind the function so we use self
    $(addBtn)
      .data("association-insertion-method", "append")
      .data("association-insertion-node", (link) => {
        return $(self.fieldsTarget)
      })

    this.colorPicker ||= new iro.ColorPicker(this.pickerTarget, options)
    // iro seems to rebind the function so we use self
    this.colorPicker.on("color:change", (event) => {
      if (!!self.activeFields) {
        self.updateInputWithMood(event, self.activeFields)
      }
    })
  }
}
