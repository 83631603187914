import iro from '@jaames/iro'

const nothingColor = "#808080"

export const options = {
  width: 290,
  height: 360,
  handleRadius: 8,
  padding: 8,
  handleOrigin: {y: 0, x: 0},
  colors: [
    nothingColor
  ],
  wheelLightness: false,
  wheelAngle: 210,
  wheelDirection: 'clockwise',
  layout: [
    {
      component: iro.ui.Wheel,
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'hue'
      }
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'saturation'
      }
    },
  ]
}
